<template>
  <section class="d-flex flex-column bg-light">
    <div
      class="container flex-grow-1 d-flex flex-column"
      data-test-voice-configuration
    >
      <data-table
        :headers="intentTableHeaders"
        :items="intents"
        @itemSelected="itemSelected"
        @returnedNull="returnedNull"
      />
    </div>
  </section>
</template>
<script>
import DataTable from "../organisims/DataTable.vue";

export default {
  name: "SearchIntents",
  components: { DataTable },
  props: {},
  computed: {
    intentTableHeaders() {
      return [
        {
          text: "Intent",
          key: "name"
        },
        {
          text: "Keywords",
          key: "keywords"
        }
      ];
    },
    intents() {
      return this.$store.getters.getIntents;
    }
    // selectedIntents() {
    //   return this.$store.state.selectedIntents.map(
    //     selectedIntent => selectedIntent.name
    //   );
    // }
  },
  methods: {
    itemSelected(item) {
      this.$emit("intentSelected", item);
    },
    returnedNull(item) {
      this.$store.dispatch("intentIsNull", item);
    }
  }
};
</script>
<style lang="scss"></style>
