<template>
  <div id="app" class="app__container d-flex">
    <div class="content-wrap">
      <app-alert
        :alertLevel="$store.state.alert.level"
        :alertTitle="$store.state.alert.title"
        :alertMessage="$store.state.alert.message"
        :show="$store.state.alert.active"
        @closeAlert="closeAlert"
      />
      <app-header />
      <main id="main" class="main-content">
        <router-view />
      </main>
      <app-footer />
    </div>
  </div>
</template>

<script>
import "./assets/scss/styles.scss";

import AppAlert from "./components/molecules/AppAlert.vue";
import AppHeader from "./components/molecules/AppHeader.vue";
import AppFooter from "./components/molecules/AppFooter.vue";

export default {
  name: "VoiceConfiguration",
  components: {
    AppAlert,
    AppHeader,
    AppFooter
  },
  mounted() {
    this.$store.dispatch("initializeApp");
  },
  methods: {
    closeAlert() {
      this.$store.dispatch("unsetMessage");
    }
  },
  watch: {
    "$route.query": "initializeApp"
  }
};
</script>

<style lang="scss"></style>
