import gql from "graphql-tag";
import apollo from "./apolloClient";

const suffixEnv = process.env.VUE_APP_NODE_ENV === "production" ? "" : "_test";
const Api = {
  async getAllIntents() {
    const result = await apollo.query({
      query: gql`
        query GetIntents {
          intents${suffixEnv}(where: { deleted_at: { _is_null: true } }) {
            id
            name
            intent_patterns(where: { deleted_at: { _is_null: true } }) {
              keyword_id_one
              keyword_id_two
              keyword_id_three
              keyword_id_four
              keyword_id_five
              keyword_id_six
              keyword_id_seven
              keyword_id_eight
            }
          }
        }
      `
    });

    const keywords = [
      "keyword_id_one",
      "keyword_id_two",
      "keyword_id_three",
      "keyword_id_four",
      "keyword_id_five",
      "keyword_id_six",
      "keyword_id_seven",
      "keyword_id_eight"
    ];

    return result.data[`intents${suffixEnv}`].map(intent => {
      intent.pattern = intent.intent_patterns.map(intentPattern =>
        keywords
          .map(keyword => intentPattern[keyword])
          .filter(keyword => keyword)
      );
      delete intent.intent_patterns;
      return intent;
    });
  }
};

export default Api;
