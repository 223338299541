<template>
  <div class="flex-grow-1 d-flex flex-column px-3 pb-5">
    <div class="action-bar d-flex justify-content-between pt-4">
      <textfield
        placeholder="Search"
        outlined
        iconTrailing="search"
        @input="handleSearchInput"
        data-test-search-intents
      />
      <pager
        :records="searchedItems.length"
        :per-page="perPage"
        @pageNumberChanged="handlePageNumberChanged"
      />
    </div>
    <table class="hs-data-table flex-grow-1 d-flex flex-column">
      <thead class="hs-data-table__head d-flex" data-test-table-head>
        <tr class="d-flex flex-grow-1">
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="hs-data-table__header"
            role="columnheader"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody class="hs-data-table__body flex-grow-1 d-flex flex-column">
        <tr
          v-for="(item, index) in currentPageItems"
          :key="index"
          class="hs-data-table__body--row d-flex flex-grow-1"
        >
          <td
            tabindex="0"
            class="hs-data-table__body--cell keyword-name fixed-header d-flex align-items-center justify-content-center"
          >
            <a
              href="#"
              class="intent-link d-flex align-items-center px-3"
              @click="handleSelectedIntent(item)"
              data-test-intent-keyword-link
            >
              <span>{{ item.name }}</span>
            </a>
          </td>
          <td
            tabindex="0"
            class="hs-data-table__body--cell keyword-links d-flex flex-wrap align-items-center flex-grow-1"
          >
            <div
              v-for="(pattern, index) in keywordPatterns(item)"
              :key="index"
              class="keyword-pattern d-flex mr-3"
            >
              <a
                v-for="keyword in pattern"
                :key="keyword"
                href="#"
                class="px-2 py-1"
                @click="handleSelectedKeyword(keyword)"
                data-test-intent-context-keyword-link
                >{{ keyword }}</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Pager from "../molecules/Pager.vue";
import Textfield from "../atoms/Textfield.vue";
import { intentKeywordAssistanceUtility } from "../../service/intentKeywordAssistanceUtility";
import { keywordConfigurationUtility } from "../../service/keywordConfigurationUtility";

export default {
  name: "DataTable",
  components: { Pager, Textfield },
  props: {
    headers: Array,
    items: Array
  },
  data() {
    return {
      perPage: this.$store.state.intentsPerPage,
      currentPage: 1,
      searchText: ""
    };
  },
  computed: {
    currentPageItems() {
      return this.searchedItems.slice(this.startIndex, this.endIndex);
    },
    endIndex() {
      const endIndex = this.startIndex + this.perPage;
      if (endIndex >= this.searchedItems.length) {
        return this.searchedItems.length;
      } else {
        return endIndex;
      }
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    searchedItems() {
      return this.items.filter(item => {
        if (item != null) {
          return item.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        } else {
          return this.$emit("returnedNull", item);
        }
      });
    }
  },
  methods: {
    keywordPatterns(intent) {
      const intentPatterns = this.$store.getters.getIntents.find(
        currentIntent => currentIntent.id == intent.id
      ).pattern;
      return intentPatterns;
    },
    handlePageNumberChanged(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleSearchInput(searchInput) {
      this.searchText = searchInput;
      this.currentPage = 1;
    },
    handleSelectedIntent(intent) {
      intentKeywordAssistanceUtility(intent.id);
      this.$emit("intentSelected", intent);
    },
    handleSelectedKeyword(keyword) {
      keywordConfigurationUtility(keyword);
      this.$emit("keywordSelected", keyword);
    }
  }
};
</script>

<style lang="scss">
.hs-data-table {
  --row-padding: 0.25rem;
  --header-padding: 0.5rem;
  // --table-border-color: #e1e1e1;
  --fixed-cell-min-width: 25em;
  --fixed-cell-width: clamp(320px, 25em, 25em);
  --cell-padding-y: 0.7rem;
  --cell-padding-x: 0.5rem;
  --cell-background-color: #f5f5f5;
  // --mdc-icon-button-size: 1rem;
  --mdc-icon-size: 1.1rem;

  &__header {
    background: var(--app-bg);
    padding-top: 1rem;
    padding-right: var(--cell-padding-x, 0.5rem);
    padding-bottom: 1rem;
    padding-left: var(--cell-padding-x, 0.5rem);

    &:first-child {
      min-width: var(--fixed-cell-width);
    }
    &:last-child {
      flex-grow: 1;
    }
  }

  &__head {
    border-bottom: solid 1px var(--table-border-color, #e1e1e1);
    // position: sticky;
    // top: calc(var(--sticky-header-height) - 2px);
    // z-index: 1;
  }

  &__body {
    border-right: solid 1px var(--table-border-color, #e1e1e1);
    border-bottom: solid 1px var(--table-border-color, #e1e1e1);
    border-left: solid 1px var(--table-border-color, #e1e1e1);

    &--row {
      &:nth-child(odd) {
        background: var(--cell-background-color, #f5f5f5);
      }
      &:nth-child(odd):not(:first-child) {
        border-top: 1px solid var(--table-border-color, #e1e1e1);
      }
      &:nth-child(odd):not(:last-child) {
        border-bottom: 1px solid var(--table-border-color, #e1e1e1);
      }
    }

    &--cell {
      padding-top: var(--cell-padding-y, 0.5rem);
      padding-right: var(--cell-padding-x, 0.5rem);
      padding-bottom: var(--cell-padding-y, 0.5rem);
      padding-left: var(--cell-padding-x, 0.5rem);
    }
  }
}

.fixed-header {
  min-width: var(--fixed-cell-width);
  word-break: break-all;
}

.action-bar {
  background: var(--app-bg);
  border-bottom: solid 1px var(--table-border-color, #e1e1e1);
  height: var(--sticky-header-height);
  position: sticky;
  top: 0;
  z-index: 1;
}

.keyword-name {
  span {
    // max-width: 20ch;
    padding-right: 1rem;
  }
}

.keyword-pattern {
  background: var(--color-grey-light);
  border-radius: 4px;
}

.keyword-links {
  border-left: 1px solid var(--table-border-color);
  gap: 1rem;
  padding: 1rem 2rem;

  a {
    color: var(--color-link);
    border-radius: 4px;
    font-family: monospace;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s ease;

    &:nth-last-child(n + 2),
    &:nth-last-child(n + 2) ~ a {
      border-radius: 0;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    &:hover,
    &:focus {
      background: var(--color-link);
      color: var(--color-grey-light);
      cursor: pointer;
    }
  }
}

.intent-link {
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}
</style>
