<template>
  <search-intents />
</template>

<script>
import SearchIntents from "./views/SearchIntents.vue";

export default {
  name: "AppView",
  components: { SearchIntents },
  computed: {},
  methods: {}
};
</script>

<style lang="scss"></style>
