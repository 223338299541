import Vue from "vue";
import Vuex from "vuex";
import Api from "../api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: "Voice Configuration",
    alert: {
      message: "This is a message",
      title: "default alert title",
      level: null,
      timeToDisplay: 5000,
      active: false
    },
    intents: [],
    intentsPerPage: 15
  },
  mutations: {
    unsetAlert(state) {
      const { alert } = state;
      alert.active = false;
      Vue.set(state, "alert", alert);
    },
    setIntents(state, intents) {
      Vue.set(state, "intents", intents);
    },
    setAlertMessage(state, { title, message, level }) {
      const { alert } = state;
      alert.title = title;
      alert.message = message;
      alert.level = level;
      alert.active = true;
      Vue.set(state, "alert", alert);
    }
    // addSelectedIntent(state, intent) {
    //   state.selectedIntents.push(intent);
    //   Vue.set(state, "selectedIntents", state.selectedIntents);
    // },
    // deleteSelectedIntent(state, intent) {
    //   const selectedIntents = state.selectedIntents.filter(selectedIntent => {
    //     return selectedIntent.name != intent.name;
    //   });
    //   Vue.set(state, "selectedIntents", selectedIntents);
    // }
  },
  getters: {
    getIntents(state) {
      return state.intents;
    }
  },
  actions: {
    async initializeApp({ commit }) {
      const intents = await Api.getAllIntents();
      commit("setIntents", intents);
    },
    setMessage({ commit, state, dispatch }, alert) {
      commit("setAlertMessage", alert);
      setTimeout(() => dispatch("unsetMessage"), state.alert.timeToDisplay);
    },
    intentIsNull({ state, dispatch }) {
      const nullItems = Object.keys(
        state.intents.filter(intent => intent == null)
      ).length;
      const severalNullItems = nullItems + ` items ` + ` returned null`;
      const singleNullItem = nullItems + ` item ` + ` returned null`;

      dispatch("setMessage", {
        title: "Error",
        level: "danger",
        message: nullItems >= 2 ? severalNullItems : singleNullItem
      });
    },
    unsetMessage({ commit }) {
      commit("unsetAlert");
    }
  }
});
